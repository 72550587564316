
import { localforage } from '../libs/localforage.js'
import { cleanObject, downloadJSON } from "../libs/util.js";
import { FIREBASEDATA } from './remote'


const categoriesStore = localforage.createInstance({ name: "categories" })
const subCategoriesStore = localforage.createInstance({ name: "subcategories" })
const subSubCategoriesStore = localforage.createInstance({ name: "subsubcategories" })
const shapesInfoStore = localforage.createInstance({ name: "shapes-info" })
const shapesDataStore = localforage.createInstance({ name: "shapes-data" })
const shapesImagesStore = localforage.createInstance({ name: "shapes-images" })

const drawingsStore = localforage.createInstance({ name: "drawings" })
const versionsStore = localforage.createInstance({ name: "versions" })
const thumbnailsStore = localforage.createInstance({ name: "thumbnails" })
const projectsStore = localforage.createInstance({ name: "projects" })
const auditionStore = localforage.createInstance({ name: "audition" })

export const FORAGEDATA = {
    async clear() {
        console.time('Clear All Databases'); // Start timing

        // Get all database names
        const databases = await indexedDB.databases();
        for (const db of databases) {
            console.log(`Deleting database: ${db.name}`);
            indexedDB.deleteDatabase(db.name);
        }

        console.timeEnd('Clear All Databases'); // Log time taken to clear
        await localforage.clear();
        console.log('All IndexedDB databases cleared.');
    },
    // async reset(){
    //     let stores = [
    //         categoriesStore,
    //         subCategoriesStore,
    //         subSubCategoriesStore,
    //         shapesInfoStore,
    //         shapesDataStore,
    //         shapesImagesStore,
    //         drawingsStore,
    //         versionsStore,
    //         thumbnailsStore,
    //         projectsStore
    //     ]
    //     for(let store of stores){
    //         let count = await store.length()
    //         for (let i = count - 1; i>=0; i--) {
    //             let key = await store.key(i)
    //             await store.removeItem(key)
    //         }
    //     }
    //     // localStorage.removeItem("localchanges","")
    // },
    async auditions() {
        let result = []
        await auditionStore.iterate((data, key) => {
            if (!data.id) {
                data.id = key
            }
            result.push(data)
        })
        return result.sort((a, b) => a.time - b.time);
    },
    async projects() {
        let projects = []


        await projectsStore.iterate((projectData, projectKey) => {
            if (typeof projectData == 'string') {
                var projectId = projectData
                projectData = {
                    id: projectId,
                    name: '',
                    drawings: [],
                }
            }
            if (!projectData.id) {
                projectData.id = projectKey
            }
            projects.push(cleanObject(projectData))
        })

        return projects.sort((a, b) => a.name?.localeCompare(b.name))
    },
    async project(projectId) {
        let project = await projectsStore.getItem(projectId)
        if (project) {
            project.drawings = await this.drawings(projectId)
        }
        return cleanObject(project)
    },
    async drawings(projectId) {
        let result = []
        await drawingsStore.iterate((drawingData, drawingID) => {
            if (typeof drawingData == 'string') {
                var drawingId = drawingData
                drawingData = {
                    id: drawingId,
                    name: '',
                    projectId,
                }
            }
            drawingData.id = drawingID
            if (drawingData.id && drawingData.project === projectId) {
                if (drawingData.versions && !drawingData.drawingVersion) {
                    drawingData.drawingVersion = drawingData.versions.reduce((max, obj) => obj.version > max ? obj.version : max, 0)
                }
                result.push(cleanObject(drawingData))
            }
        })
        result = result.sort((a, b) => a.name.localeCompare(b.name))
        return result
    },
    async drawing(drawingId) {
        let drawing = await drawingsStore.getItem(drawingId)
        if (!drawing) {
            return null
        }
        // if(!drawing.versions){
        //   drawing.versions = []
        //   if(drawing.drawingVersion){
        //     drawing.versions.push({
        //       createdAt: drawing.createdAt,
        //       version: drawing.drawingVersion
        //     })
        //   }
        // }
        if (drawing.drawingVersion === undefined) {
            if (drawing.versions) {
                drawing.drawingVersion = this._getLastVersion(drawing.versions)
            } else {
                drawing.drawingVersion = -1
                drawing.versions = [{
                    createdAt: drawing.createdAt,
                    version: -1
                }]
            }
        }
        return cleanObject(drawing)
    },
    async version(drawingId, drawingVersion = 0) {
        if (drawingVersion === 0) {
            return {}
        }
        let key = "" + drawingVersion === "-1" ? `${drawingId}` : `${drawingId}-${drawingVersion}`
        return versionsStore.getItem(key)
    },
    async shapes() {
        let shapes = []
        let count = await shapesInfoStore.length()
        for (let i = 0; i < count; i++) {
            let key = await shapesInfoStore.key(i)
            let data = await shapesInfoStore.getItem(key)
            data.svg = await shapesImagesStore.getItem(key)
            data.sizes = await shapesDataStore.getItem(key)
            data.id = key
            shapes.push(data)
        }

        return cleanObject(shapes)
    },
    async categories() {
        let cats = []
        let count = await categoriesStore.length()
        for (let i = 0; i < count; i++) {
            let key = await categoriesStore.key(i)
            let category = await categoriesStore.getItem(key)
            let label = category?.label || ""
            cats.push({ key, id: key, value: label, label })
        }
        return cats.sort((a, b) => a.value.localeCompare(b.value))
    },
    async subCategories() {
        let cats = []
        let count = await subCategoriesStore.length()
        for (let i = 0; i < count; i++) {
            let key = await subCategoriesStore.key(i)
            let cat = await subCategoriesStore.getItem(key);
            if (cat) {
                let { label, parentId, parentName } = cat
                cats.push({ key, id: key, value: label, label, parentId, parentName })
            }
        }
        return cats.sort((a, b) => a.value.localeCompare(b.value))
    },
    async subSubCategories() {
        let cats = []
        let count = await subSubCategoriesStore.length()
        for (let i = 0; i < count; i++) {
            let key = await subSubCategoriesStore.key(i)
            let { label, parentId, parentName } = await subSubCategoriesStore.getItem(key)
            cats.push({ key, id: key, value: label, label, parentId, parentName })
        }
        return cats.sort((a, b) => a.value.localeCompare(b.value))
    },

    async editProject(id, data) {
        let saved = await projectsStore.getItem(id)
        await projectsStore.setItem(id, Object.assign({}, saved, data))
    },
    async editDrawing(id, data) {
        let saved = await drawingsStore.getItem(id)
        await drawingsStore.setItem(id, Object.assign({}, saved, data))
    },
    async editShape(shapeID, data) {
        const { id, ...copy } = data;
        let saved = await shapesInfoStore.getItem(shapeID)
        await shapesInfoStore.setItem(shapeID, { ...saved, ...copy })
    },
    async createAudition(auditionID, data) {
        const { id, ...copy } = data;
        await auditionStore.setItem(auditionID, copy)
    },
    async editAudition(auditionId, data) {
        const { id, ...copy } = data;
        let saved = await auditionStore.getItem(auditionId)
        await auditionStore.setItem(auditionId, cleanObject({ ...saved, ...copy }))
    },
    async deleteAudition(auditionId) {
        await auditionStore.removeItem(auditionId)
    },
    async createProject(options) {
        await projectsStore.setItem(options.id, options)
    },
    async createDrawing(options) {
        await drawingsStore.setItem(options.id, options)
    },
    async storeVersionThumbnail(drawing, version, thumbnailBlob) {
        let key = "" + version === "-1" ? `${drawing}` : `${drawing}-${version}`
        await thumbnailsStore.setItem(key, thumbnailBlob)
    },
    async storeVersionData(drawing, version, data) {
        let key = "" + version === "-1" ? `${drawing}` : `${drawing}-${version}`
        await versionsStore.setItem(key, data)
    },
    async createVersion(drawingId, version, data, thumbnailBlob) {
        await this.storeVersionData(drawingId, version, data)
        await this.storeVersionThumbnail(drawingId, version, thumbnailBlob)
    },
    async createShape(options) {
        await shapesInfoStore.setItem(options.id, options)
        await shapesDataStore.setItem(options.id, options.sizes)
    },
    async deleteShape(id) {
        await shapesInfoStore.removeItem(id)
        await shapesDataStore.removeItem(id)
        await shapesImagesStore.removeItem(id)
    },
    async deleteProject(id) {
        let projectInfoSaved = await this.project(id)
        while (projectInfoSaved?.drawings?.length) {
            let drawing = projectInfoSaved.drawings.shift()
            await this.deleteDrawing(drawing.id)
        }
        await projectsStore.removeItem(id)
    },
    async deleteDrawing(id) {
        let drawingInfoSaved = await this.drawing(id)
        if (drawingInfoSaved?.versions?.length) {
            let version = drawingInfoSaved.versions.shift()
            await this.deleteVersion(id, version.version)
        }
        await drawingsStore.removeItem(id)
        await thumbnailsStore.removeItem(id)
    },
    async deleteVersion(id, version = 0) {
        await versionsStore.removeItem(`${id}-${version}`)
        await thumbnailsStore.removeItem(`${id}-${version}`)
        let drawingInfoSaved = await this.drawing(id)
        if (drawingInfoSaved) {
            let versions = drawingInfoSaved.versions
            let versionItem = versions.find(el => el.version === version)
            versions.splice(versions.indexOf(versionItem), 1)
            let drawingData = {
                versions,
                drawingVersion: 0
            }
            await this.editDrawing(id, drawingData)
        }
    },


    async versionThumbnail(drawing, version) {
        let key = "" + version === "-1" ? `${drawing}` : `${drawing}-${version}`
        return thumbnailsStore.getItem(key)
    },

    deleteLocalChanges() {
        auditionStore.clear();
    },

    // Function to collect data from specific tables in IndexedDB
    async dump() {
        let dump = []

        let auditionsLocal = await FORAGEDATA.auditions()

        for (let audition of auditionsLocal) {
            switch (audition.type) {
                case 'project-create': {
                    let data = await FORAGEDATA.project(audition.project)
                    dump.push({ ...audition, data })
                    break
                }
                case 'project-edit': {
                    let data = await FORAGEDATA.project(audition.project)
                    dump.push({ ...audition, data })
                    break
                }
                case 'project-delete': {
                    dump.push({ ...audition })
                    break
                }
                case 'drawing-create': {
                    let data = await FORAGEDATA.drawing(audition.drawing)
                    dump.push({ ...audition, data })
                    break
                }
                case 'drawing-edit': {
                    let data = await FORAGEDATA.drawing(audition.drawing)
                    dump.push({ ...audition, data })
                    break
                }
                case 'drawing-delete': {
                    dump.push({ ...audition })
                    break
                }
                case 'version-create': {
                    let data = await FORAGEDATA.version(audition.drawing, audition.version)
                    dump.push({ ...audition, data })
                    break
                }
                case 'version-delete': {
                    dump.push({ ...audition })
                    break
                }
            }
        }





        //
        //
        // const data = {};
        // let stores = [
        //     {key: 'drawingsInfo', store: drawingsStore },
        //     {key: 'drawingsData', store: versionsStore },
        //     {key: 'projects', store: projectsStore }
        // ]
        // for(let store of stores){
        //     data[store.key] = {}
        //     let count = await store.store.length()
        //     for (let i = count - 1; i>=0; i--) {
        //         let key = await store.store.key(i)
        //         data[store.key][key] = await store.store.getItem(key)
        //     }
        //     console.log(store.key,count)
        // }
        return dump;
    },

    // Function to gather data and download as JSON
    async export(tables) {
        const data = await this.dump(tables);
        downloadJSON(data, "dump.json");
    },

    async createCategory(options) {
        await categoriesStore.setItem(options.id, options)
    },

    async createSubcategory(options) {
        await subCategoriesStore.setItem(options.id, options)
    },
    async createSubsubcategory(options) {
        await subSubCategoriesStore.setItem(options.id, options)
    },

}